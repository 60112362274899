fieldset legend {
  width: 0;
}

.loader {
  position: relative;
}

.loader:before {
  display: block;
  content: "";
  position: absolute;
  background-color: transparent;
  background-image: url("./assets/images/favicon.png");
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  z-index: 1050;
}

.loader:after {
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 1040;
}

@-webkit-keyframes rotating {
  from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotateY(360deg);
      -o-transform: rotateY(360deg);
      transform: rotateY(360deg);
  }
}

@keyframes rotating {
  from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -ms-transform: rotateY(360deg);
      -moz-transform: rotateY(360deg);
      -webkit-transform: rotateY(360deg);
      -o-transform: rotateY(360deg);
      transform: rotateY(360deg);
  }
}